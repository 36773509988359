import { useConfirmStore } from 'context';

export function useConfirmMessage() {
  //@ts-ignore
  const { updateOpenConfirm, updateConfirmModalInfo } = useConfirmStore();

  const onClose = (reject) => () => {
    updateOpenConfirm(false);
    updateConfirmModalInfo({});
    reject();
  };

  const onConfirm = (resolve) => () => {
    updateOpenConfirm(false);
    updateConfirmModalInfo({});
    resolve();
  };

  const openConfirmModal = ({ ...props }) => {
    return new Promise((resolve, reject) => {
      updateConfirmModalInfo({
        ...props,
        onClose: onClose(reject),
        onConfirm: onConfirm(resolve),
      });

      updateOpenConfirm(true);
    });
  };

  return { openConfirmModal };
}

