import cls from './Login.module.scss'
import {Image} from "react-bootstrap";
import {TextField, MerqButton, PATHS} from "shared";
import { Form, useField } from 'react-final-form'
import { LoginForm } from './LoginForm'
import { Credentials } from '../types';
import { loginStore } from '../store'
import { redirect, useNavigate } from "react-router-dom";

const Login = () => {

  const { login } = loginStore()
  const navigate = useNavigate()

  return (
    <div className={cls.container}>
      <div className={cls.wrapper}>
        <div className={cls.logoWrapper}>
          <Image src='/mobile-logo.png' width='150px'/>
          <p className={cls.title}>
            admin
          </p>
        </div>
        <div className={cls.formWrapper}>
          <Form<Credentials> onSubmit={
            ({email, password}) => login({email, password, onSuccess: () => navigate(PATHS.MAIN)})
          }>
            {({ handleSubmit }) => (
              <LoginForm onSubmit={handleSubmit}/>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;