import { FieldInputProps, FieldMetaState } from 'react-final-form'
import { TextFieldProps, FormControl, FormHelperText } from "@mui/material";
import { TextField } from "shared";

type Props<FiledValue> = {
  input : FieldInputProps<FiledValue>;
  meta:  FieldMetaState<FiledValue>
} & TextFieldProps

export const FormTextField = <FieldValue, >({input, meta, ...props}: Props<FieldValue>) => {

  const { error, touched } = meta;
  const hasError = touched && error;

  return (
    <FormControl
      fullWidth={props.fullWidth}
    >
      <TextField
        error={hasError}
        {...props}
        {...input}
      />
      {
        hasError &&
        <FormHelperText error={hasError}>
          {error}
        </FormHelperText>
      }
    </FormControl>
  )
}