import {
  BAD_REQUEST_ERROR_INFO,
  HttpServiceError,
  INTERNAL_ERROR_INFO,
  NOT_FOUND_ERROR_INFO,
  UNAUTHORIZED_HTTP_INFO,
} from '../../../HttpService';
import { ApiDataError } from '../../error';

type ApiErrorInfo = {
  title: string;
  status: number;
  detail?: string;
  errorMessage?: string;
  errorCode?: string;
  description?: string;
  errors?: Array<{
    message: string;
    additionalInfo: {
      errorCustomField?: string;
    };
  }>;
};

type ClientApiErrorInfo = {
  title: string;
  detail?: string;
  status: number;
  errorMessage?: string;
  errorCode?: string;
  description?: string;
  errors?: Array<{
    message: string;
    additionalInfo: {
      errorCustomField?: string;
    };
  }>;
};

// Если нет response, то это Network Error (403...)
export const getMainApiErrorRes = (
  error: HttpServiceError<ApiErrorInfo, ApiErrorInfo>,
): ClientApiErrorInfo => {
  if (error?.response?.status === UNAUTHORIZED_HTTP_INFO.code) {
    return {
      title: UNAUTHORIZED_HTTP_INFO.message,
      status: UNAUTHORIZED_HTTP_INFO.code,
      errors: [
        {
          message: UNAUTHORIZED_HTTP_INFO.message,
          additionalInfo: {},
        },
      ],
    };
  }

  if (error.response?.data) {
    return {
      title: error.response?.data?.detail || error.response?.data?.title,
      description: error.response?.data?.description,
      status: error.response?.data.status,
      errorCode: error.response?.data.errorCode,
      errors: error.response?.data?.errors?.map((err) => {
        return {
          message: err.message,
          additionalInfo: err.additionalInfo,
        };
      }),
    };
  }

  return {
    title: INTERNAL_ERROR_INFO.message,
    status: INTERNAL_ERROR_INFO.httpCode,
    errors: [
      {
        message: INTERNAL_ERROR_INFO.message,
        additionalInfo: {},
      },
    ],
  };
};

export const formatApiError = (
  mainApiError: HttpServiceError<ApiErrorInfo, ApiErrorInfo>,
): ApiDataError => {
  const errorResponse: ClientApiErrorInfo = getMainApiErrorRes(mainApiError);

  const { title, detail, status, errorCode, description } = errorResponse;

  if (errorResponse.errors && errorResponse.errors.length) {
    return new ApiDataError({
      status,
      description,
      message: detail || title,
      errors: errorResponse.errors,
      code: errorCode,
    });
  }

  switch (status) {
    case BAD_REQUEST_ERROR_INFO.code:
      return new ApiDataError({
        message: BAD_REQUEST_ERROR_INFO.message,
        errors: [
          {
            message: BAD_REQUEST_ERROR_INFO.message,
            additionalInfo: {},
          },
        ],
      });

    case NOT_FOUND_ERROR_INFO.code:
      return new ApiDataError({
        message: NOT_FOUND_ERROR_INFO.message,
        errors: [
          {
            message: NOT_FOUND_ERROR_INFO.message,
            additionalInfo: {},
          },
        ],
      });

    default:
      return new ApiDataError({
        message: INTERNAL_ERROR_INFO.message,
        errors: [
          {
            message: INTERNAL_ERROR_INFO.message,
            additionalInfo: {},
          },
        ],
      });
  }
};
