export const testUser = {
  id: '15e65c48-1e7d-439a-974f-6336cdcd7d94',
  email: 'bla@z.c',
  phone: '+79051844376',
  subscribtion_expires_at: '2023-04-03 00:00:00',
  tg_chat_id: '483787703',
  tg_user_id: '483787703',
  tg_username: 'Zhravchuk',
  plan: 'full_access',
  favorite_only: false,
  referrer: 'moex'
}

export const testTrans = [
  {
    plan_id: 'early_adopter',
    status: 'COMPLETE',
    amount: 1000,
    updated_at: '2022-04-29 08:25:26'
  },
  {
    plan_id: 'early_adopter',
    status: 'COMPLETE',
    amount: 1000,
    updated_at: '2022-03-29 08:25:26'
  }
]

export const testPlans = [
  {
    id: 'foreign_access_3',
    name: 'Иностранные бумаги на 3 месяца',
    desc: 'Доступ к иностранным бумагам на 3 месяца',
    price: '12825.00',
    days: 90,
    access_level: 'foreign_access',
    is_recurrent: false
  },
  {
    id: 'foreign_access_6',
    name: 'Иностранные бумаги на 6 месяцев',
    desc: 'Доступ к иностранным бумагам на 6 месяцев',
    price: '24300.00',
    days: 180,
    access_level: 'foreign_access',
    is_recurrent: false
  },
  {
    id: 'foreign_access_12',
    name: 'Иностранные бумаги на 12 месяцев',
    desc: 'Доступ к иностранным бумагам на 12 месяцев',
    price: '43200.00',
    days: 365,
    access_level: 'foreign_access',
    is_recurrent: false
  },
  {
    id: 'full_access_3',
    name: 'Полный доступ на 3 месяца',
    desc: 'Доступ ко всем бумагам в Merqurius на 3 месяца',
    price: '14250.00',
    days: 90,
    access_level: 'full_access',
    is_recurrent: false
  },
  {
    id: 'full_access_6',
    name: 'Полный доступ на 6 месяцев',
    desc: 'Доступ ко всем бумагам в Merqurius на 6 месяцев',
    price: '27000.00',
    days: 180,
    access_level: 'full_access',
    is_recurrent: false
  },
  {
    id: 'full_access_12',
    name: 'Полный доступ на 12 месяцев',
    desc: 'Доступ ко всем бумагам в Merqurius на 12 месяцев',
    price: '48000.00',
    days: 365,
    access_level: 'full_access',
    is_recurrent: false
  },
  {
    id: 'full_access',
    name: 'Полный доступ',
    desc: 'Доступ ко всем бумагам в Merqurius',
    price: '5000.00',
    days: 30,
    access_level: 'full_access',
    is_recurrent: true
  },
  {
    id: 'foreign_access',
    name: 'Иностранные бумаги',
    desc: 'Доступ к иностранным бумагам',
    price: '4500.00',
    days: 30,
    access_level: 'foreign_access',
    is_recurrent: true
  },
  {
    id: 'early_adopter_3',
    name: 'Российские бумаги на 3 месяца',
    desc: 'Доступ к российским бумагам на 3 месяцa',
    price: '4275.00',
    days: 90,
    access_level: 'early_adopter',
    is_recurrent: false
  },
  {
    id: 'early_adopter_6',
    name: 'Российские бумаги на 6 месяцев',
    desc: 'Доступ к российским бумагам на 6 месяцев',
    price: '8100.00',
    days: 180,
    access_level: 'early_adopter',
    is_recurrent: false
  },
  {
    id: 'early_adopter_12',
    name: 'Российские бумаги на 12 месяцев',
    desc: 'Доступ к российским бумагам на 12 месяцев',
    price: '14400.00',
    days: 365,
    access_level: 'early_adopter',
    is_recurrent: false
  },
  {
    id: 'early_adopter',
    name: 'Российские бумаги',
    desc: 'Доступ к российскийм бумагам',
    price: '1500.00',
    days: 30,
    access_level: 'early_adopter',
    is_recurrent: true
  }
]