import {Container} from "react-bootstrap";
import cls from './Main.module.scss'
import {useEffect, useState} from "react";
import {CurrenciesNames, ServiceStatistics} from "shared/types/common";
import { api } from "shared";
import {Skeleton} from "@mui/material";

const Main = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [statistics, setStatistics] = useState<ServiceStatistics | undefined>(undefined)
  const [currNames, setCurrName] = useState<CurrenciesNames>({})
  const [total, setTotal] = useState(0)

  useEffect(() => {
    api().getStatistics()
      .then((res) => {
        setStatistics(res.data.statistics)
        setCurrName(res.data.currenciesNames)
        setTotal(res.data.statistics.securities.count)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Container fluid className='p-5'>
      <h2>
        Обзор <b>MerquriusApp</b>
      </h2>
      <div className={cls.stats}>

        <p>
          Всего пользователей:&nbsp;
          {
            isLoading
              ? <Skeleton variant='text' width={50} sx={{fontSize: '18px'}}/>
              : <b>{statistics?.allUsers || '-'}</b>
          }
        </p>

        <p>
          Активных пользователей:&nbsp;
          {
            isLoading
              ? <Skeleton variant='text' width={50} sx={{fontSize: '18px'}}/>
              : <b>{statistics?.activeUsers || '-'}</b>
          }
        </p>

        <div>
          Бумаги
          <ul>
            {
              isLoading || !statistics
                ? <>
                </>
                : Object.entries(statistics.securities?.currencies)?.map(([curr, count]) => {

                  return (
                    <li key={curr}>
                      {currNames?.[curr]}: <b>{count}</b>
                    </li>
                  )
                })
            }
            <li>
              <b>Всего: {total}</b>
            </li>
          </ul>
        </div>

      </div>
    </Container>
  );
};

export default Main;