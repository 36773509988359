import cls from './Sidebar.module.scss'
import {useCallback, useMemo, useState} from "react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  GiftIcon,
  LogoutIcon,
  OverviewIcon,
  PATHS,
  PlansIcon,
  SecuritiesIcon,
  MessageIcon
} from "shared";
import classNames from "classnames";
import {NavLink, useLocation} from "react-router-dom";
import {UsersIcon} from "shared";

export const Sidebar = () => {

  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const isLogin = useMemo(() => {
    return location.pathname === PATHS.LOGIN
  }, [location])

  const getColor = useCallback((path: string): string => {
    if (path === location.pathname) return '#FFF'
    else return '#999'
  }, [location])


  return (
    <div className={classNames(cls.container, {[cls.hide]: isLogin}, {[cls.open]: isOpen})}>
      <div className={cls.closeBtn} onClick={() => setIsOpen(prev => !prev)}>
        {
          isOpen
            ? <ArrowLeftIcon height={12}/>
            : <ArrowRightIcon height={12}/>
        }

      </div>
      <div className={cls.inner}>
        <div className={cls.linksWrapper}>

          <NavLink to={PATHS.MAIN} className={({isActive}) => classNames(cls.customLink, {[cls.active]: isActive})}>
            <OverviewIcon stroke={getColor(PATHS.MAIN)} width={22}/>
            Обзор
          </NavLink>

          <NavLink to={PATHS.USERS} className={({isActive}) => classNames(cls.customLink, {[cls.active]: isActive})}>
            <UsersIcon stroke={getColor(PATHS.USERS)} width={22}/>
            Пользователи
          </NavLink>

          <NavLink to={PATHS.SECURITIES} className={({isActive}) => classNames(cls.customLink, {[cls.active]: isActive})}>
            <SecuritiesIcon stroke={getColor(PATHS.SECURITIES)} height={22}/>
            Бумаги
          </NavLink>

          <NavLink to={PATHS.PLANS} className={({isActive}) => classNames(cls.customLink, {[cls.active]: isActive})}>
            <PlansIcon stroke={getColor(PATHS.PLANS)} width={22}/>
            Тарифы
          </NavLink>

          <NavLink to={PATHS.PROMO} className={({isActive}) => classNames(cls.customLink, {[cls.active]: isActive})}>
            <GiftIcon stroke={getColor(PATHS.PROMO)} width={22} height={22}/>
            Промо
          </NavLink>

          <NavLink to={PATHS.MESSAGING} className={({isActive}) => classNames(cls.customLink, {[cls.active]: isActive})}>
            <MessageIcon stroke={getColor(PATHS.MESSAGING)} width={22} height={22} fill={getColor(PATHS.MESSAGING)}/>
            Рассылка
          </NavLink>

          <div className='flex-grow-1'></div>

          <div onClick={() => localStorage.clear()}>
            <NavLink to={PATHS.LOGIN} className={({isActive}) => classNames(cls.customLink, {[cls.active]: isActive})}>
              <LogoutIcon stroke={getColor(PATHS.LOGIN)} width={22} height={22}/>
              Выйти
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};