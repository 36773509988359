import qs from 'query-string';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import axiosRetry from 'axios-retry';

import { DataError } from '../DataError';

type ErrorHandler = (error: HttpServiceError<unknown, unknown>) => unknown;
type ErrorFormatter<
  CurrentDataError extends DataError,
  //  eslint-disable-next-line
  > = (error: HttpServiceError<any, any>) => CurrentDataError;

export interface HttpService extends AxiosInstance {
  init: (config: HttpServiceInitConfig) => HttpService;
  subscribeOnError(func: ErrorHandler): void;
  initErrorFormatter<CurrentDataError extends DataError>(
    func: ErrorFormatter<CurrentDataError>,
  ): void;
}

export type HttpServiceError<T, D> = AxiosError<T, D>;

export type HttpServiceResponse<T, D = T> = AxiosResponse<T, D>;

export type HttpServicePromise<T> = AxiosPromise<T>;

type HttpServiceConfig = AxiosRequestConfig;

type HttpServiceInitConfig = Pick<HttpServiceConfig, 'baseURL' | 'headers'>;

export const createHttpService = (
  config: HttpServiceConfig = {},
): HttpService => {
  const errorListeners: ErrorHandler[] = [];
  let errorFormatter: ErrorFormatter<DataError<Record<string, unknown>>> = () =>
    new DataError({
      message: 'Неизвестная ошибка',
      errors: [{ message: 'Неизвестная ошибка', additionalInfo: {} }],
    });

  const httpService = axios.create({
    ...config,
    paramsSerializer: {
      serialize: ({ serializerParams, ...params }) => {
        return qs.stringify(params, serializerParams);
      },
    },
  }) as HttpService;

  axiosRetry(httpService, { retries: 3 });

  httpService.subscribeOnError = (func) => {
    errorListeners.push(func);
  };

  httpService.initErrorFormatter = (func) => {
    errorFormatter = func;
  };

  httpService.interceptors.response.use(
    (res) => res,
    (error) => {
      errorListeners.forEach((func) => {
        func(error);
      });

      return Promise.reject(error);
    },
  );

  httpService.init = (newConfig?: HttpServiceInitConfig) => {
    if (newConfig) {
      httpService.defaults.baseURL = newConfig.baseURL;
    }

    return httpService;
  };

  return httpService;
};
