import { formatDate } from '../../../lib'

type Props = {
  date: Date | string;
}

export const DateCell = ({ date }: Props) => {
  return (
    <div className="fw-semibold">
      { date ?formatDate(date) : '__.__.__' }
    </div>
  )
}