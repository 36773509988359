import React, {ReactNode, useContext, useState} from 'react';

const ConfirmContext = React.createContext({});

export const useConfirmStore = () => useContext(ConfirmContext);

export const ConfirmProvider = ({ children, content }: { children: ReactNode, content: any }) => {
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [confirmModalInfo, setConfirmModalInfo] = useState({});

  const value = {
    isOpenConfirm,
    confirmModalInfo,
    updateOpenConfirm: setOpenConfirm,
    updateConfirmModalInfo: setConfirmModalInfo,
  };

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      {content(isOpenConfirm, confirmModalInfo)}
    </ConfirmContext.Provider>
  );
};
