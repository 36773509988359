import cls from './MainLayout.module.scss'
import {ReactNode} from "react";

export const MainLayout = ({children}: {children: ReactNode}) => {
  return (
    <div className={cls.container}>
      {children}
    </div>
  );
};
