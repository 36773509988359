import cls from './Users.module.scss'
import {Container} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from 'react';
import { SingleUser } from "./components/SingleUser";
import { UsersGroup } from "./components/UsersGroup";
import { Placeholder } from "shared";

const Users = () => {

  const [activeTab, setActiveTab] = useState<string>('user');

  return (
    // <Container fluid className='p-5 d-flex flex-column h-100'>
    //   <h2>
    //     Пользователи
    //   </h2>
    //   <Tabs
    //     activeKey={activeTab}
    //     onSelect={(k) => setActiveTab(k || "")}
    //     className="mt-4"
    //   >
    //     <Tab eventKey="user" title="Пользователь" className='h-100'>
    //       <div className={cls.tabContentContainer}>
    //         <SingleUser/>
    //       </div>
    //     </Tab>
    //     <Tab eventKey="group" title="Группа" className='h-100'>
    //       <div className={cls.tabContentContainer}>
    //         <UsersGroup/>
    //       </div>
    //     </Tab>
    //   </Tabs>
    // </Container>
    <Placeholder/>
  );
};

export default Users;