import React from 'react';
import './styles/global.scss';
import { AppRouter } from "./providers/router";
import { MainLayout } from "shared";
import { Sidebar } from "widgets";
import { ToastContainer, toast } from 'react-toastify';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';
import dayjs from "dayjs";
import 'dayjs/locale/ru'
import 'react-toastify/dist/ReactToastify.css';

import { configService, initApiHttpClient } from 'shared'

import { ConfirmProvider } from './providers/ConfirmProvider'
import { ThemeProvider, createTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjsPluginUTC from 'dayjs-plugin-utc'

configService.init({
  apiUrl: process.env.REACT_APP_API_BASE_URL as string,
  token: window.localStorage.getItem("token") || ""
})

initApiHttpClient()

dayjs.locale('ru')
dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

function App() {
  const theme = createTheme()

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
          <ConfirmProvider>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <MainLayout>
              <Sidebar/>
              <AppRouter/>
            </MainLayout>
          </ConfirmProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
