import {api, configService, initApiHttpClient, localStorage} from 'shared'
import { Credentials } from '../types'

class LoginStore {
  public login(
    {
      email,
      password,
      onSuccess
    }: Credentials & { onSuccess: () => void }) {
    api().login(email, password)
      .then((res) => {
        const token = res.data?.token
        localStorage().setToken(token)

        configService.init({
          apiUrl: process.env.REACT_APP_API_BASE_URL as string,
          token: token
        })

        initApiHttpClient()
        onSuccess()
      })
      .catch((err) => console.log(err))
  }
}

export const loginStore = () => new LoginStore();