import Button from "react-bootstrap/Button";
import { ButtonProps } from "react-bootstrap";
import './Button.scss'

export const MerqButton = (props: ButtonProps) => {
  return (
    <Button {...props}>
      {props.children}
    </Button>
  );
};