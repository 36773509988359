import { makeAutoObservable } from 'mobx';

export type AsyncState = {
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage?: string;
  errorCode?: string;
  loaderState?: string;
};

export class AsyncStateStore {
  private state: AsyncState = {
    isLoading: true,
    isSuccess: false,
  };

  private readonly defaultLoadingValue: boolean;

  constructor(defaultLoadingValue: boolean) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.defaultLoadingValue = defaultLoadingValue;

    this.state = {
      isLoading: this.defaultLoadingValue,
      isSuccess: false,
    };
  }

  public start = () => {
    this.state = {
      isLoading: true,
      isSuccess: false,
    };
  };

  public success = () => {
    this.state = {
      isLoading: false,
      isSuccess: true,
    };
  };

  public fail = (errorMessage: string, errorCode?: string) => {
    this.state = {
      isLoading: false,
      isSuccess: false,
      errorMessage,
      errorCode,
    };
  };

  public reset = () => {
    this.state = {
      isLoading: this.defaultLoadingValue,
      isSuccess: false,
      errorMessage: '',
      errorCode: '',
    };
  };

  get isLoading() {
    return this.state.isLoading;
  }

  get isSuccess() {
    return this.state.isSuccess;
  }

  get isFailed() {
    return Boolean(!this.state.isSuccess && this.state.errorMessage);
  }

  get errorMessage() {
    return this.state.errorMessage;
  }

  get errorCode() {
    return this.state.errorCode;
  }

  get loaderState() {
    return this.state.loaderState;
  }
}

export const createAsyncStateStore = (defaultLoadingValue: boolean = true) =>
  new AsyncStateStore(defaultLoadingValue);
