import {ReactNode} from "react";
import {Navigate} from "react-router-dom";
import {localStorage, PATHS} from 'shared'

interface  SecuredRouterProps {
  children: ReactNode
}

export const SecuredRouter = ({children}: SecuredRouterProps) => {

  const token = window.localStorage.getItem('token')

  if (!token) {
    localStorage().clear()
    return (
      <Navigate to={PATHS.LOGIN}/>
    )
  } else {
    return (
      <div>
        {children}
      </div>
    )
  }
}