import {memo, Suspense, useCallback} from "react";
import { Route, Routes } from "react-router-dom";
import {ApprouterProps} from "shared/types/route";
import { routeConfig } from "../config/routeConfig";
import {SecuredRouter} from "./SecuredRouter";

const AppRouter = () => {
  const renderWithWrapper = useCallback((route: ApprouterProps) => {
    const element = (
      <Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense>
    );

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          route.authOnly ? (
            <SecuredRouter>{element}</SecuredRouter>
          ) : (
            element
          )
        }
      />
    );
  }, []);

  return <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>;
}

export default memo(AppRouter);
