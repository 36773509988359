export type DataErrorsItem<AdditionalInfo = Record<string, unknown>> = {
  message: string;
  additionalInfo: AdditionalInfo;
};

export type DataErrorProps<AdditionalInfo = Record<string, unknown>> = {
  errors: DataErrorsItem<AdditionalInfo>[];
  message: string;
  code?: string;
  status?: number;
  description?: string;
};

export class DataError<AdditionalInfo = Record<string, unknown>> extends Error {
  errors: DataErrorsItem<AdditionalInfo>[];

  message: string;

  code?: string;

  status?: number;

  description?: string;

  constructor(error: DataErrorProps<AdditionalInfo>) {
    super(error.message);
    this.errors = error.errors;
    this.message = error.message;
    this.code = error.code;
    this.status = error.status;
    this.description = error.description;
  }
}
