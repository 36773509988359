import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormHelperText } from "@mui/material";

interface FormDateTimePickerProps extends FieldRenderProps<Date, HTMLElement> {
  label?: string;
}

export const FormDateTimePicker: React.FC<FormDateTimePickerProps> = (
  {
    input: { name, onChange, value, ...restInput },
    meta,
    label,
    ...rest
  }) => {

  const { error, invalid } = meta;
  const hasError = invalid && error;

  return (
    <div>
      <DateTimePicker
        label={label}
        value={value || null}
        onChange={onChange}
        ampm={false}
        format="DD MMMM YYYY HH:mm"
        {...rest}
        {...restInput}
      />
      {
        hasError &&
        <FormHelperText error={hasError}>
          {error}
        </FormHelperText>
      }
    </div>
  )
}