class LocalStorage {
  public getToken() {
    return window.localStorage.getItem('token')
  }

  public setToken(token: string) {
    window.localStorage.setItem('token', token)
  }

  public clear() {
    window.localStorage.clear()
  }
}

export const localStorage = () => new LocalStorage();