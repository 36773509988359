import cls from './Placeholder.module.scss';
import { MainteneneIcon } from '../../icons';
import {Container} from "react-bootstrap";

export const Placeholder = () => {
  return (
    <Container fluid className={cls.wrapper}>
      <MainteneneIcon width={200} height={200}/>
      <h2>
        Coming soon...
      </h2>
    </Container>
  )
}