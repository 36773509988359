import {testPlans, testTrans, testUser} from "./testData";
import {TransactionType, User} from "../../types/user";
import {PlanType} from "../../types/plan";
import axios, {AxiosResponse} from "axios";
import {CurrenciesNames, ServiceStatistics} from "../../types/common";
import {toast} from "react-toastify";
import { redirect } from "react-router-dom";
import { PATHS } from '../../consts/paths'
import { localStorage } from '../localStorage'

const baseURL = process.env.REACT_APP_API_BASE_URL

const apiClient = axios.create({
  baseURL: baseURL
})

apiClient.interceptors.response.use(function (response) {
  return response
}, function (error) {
  toast(error.data)
  if (error?.response?.statut === 401 || error?.response?.statut === 403) {
    redirect(PATHS.LOGIN)
  }
  return Promise.reject(error)
})

class Api {

  private readonly token = localStorage().getToken()

  async login(email: string, password: string): Promise<AxiosResponse<{token: string}>> {
    return apiClient.post('/admin/login', { email, password })
  }

  async getStatistics(): Promise<AxiosResponse<{ statistics: ServiceStatistics, currenciesNames: CurrenciesNames }>> {
    return apiClient.get('/admin/statistics', {
      headers: {
        Authorization: `Jwt ${this.token}`
      }
    })
  }

  async getUser(email:string): Promise<User> {
    return testUser
  }
}

export const api = () => new Api();

export async function getUser(email:string): Promise<User> {
  return testUser
}

export async function getTransactions(email: string): Promise<TransactionType[]> {
  await setTimeout(() => {console.log('')}, 1000)
  return testTrans
}

export async function getPlans(): Promise<PlanType[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(testPlans)
    }, 500)
  })
}