import React from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@mui/material'

import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


type Props = {
  isOpen: boolean,
  info?: {
    onClose?: () => void;
    onConfirm?: () => void;
    message?: string;
  }
}

export const ConfirmModal = ({ isOpen = false, info = {} }: Props) => {

  const { message, onClose, onConfirm } = info

  const confirmModalIsOpen = isOpen && Boolean(onClose) && Boolean(onConfirm);

  return (
    <Dialog
      open={confirmModalIsOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{justifyContent: 'space-between'}}>
        <Button onClick={onClose} color="error" variant="contained">НЕТ</Button>
        <Button onClick={onConfirm} variant="contained">ДА</Button>
      </DialogActions>
    </Dialog>
  )
}