import { MobxQuery } from '@astral/mobx-query';
import {toast} from "react-toastify";

export const cacheService = new MobxQuery({
  onError: (error) => {
    toast(error as string)
  },
  fetchPolicy: 'network-only',
  enabledAutoFetch: true
});

export type CacheService = typeof cacheService
