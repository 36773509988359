import { ConfirmProvider as ConfirmContextProvider } from 'context';
import { ConfirmModal } from 'widgets';
import { ReactNode } from "react";

export const ConfirmProvider = ({ children }: {children: ReactNode }) => {
  return (
    <ConfirmContextProvider
      content={(isOpen, info) => <ConfirmModal isOpen={isOpen} info={info} />}
    >
      {children}
    </ConfirmContextProvider>
  );
};