import {ApprouterProps} from "shared/types/route";
import {PATHS} from "shared";
import Main from "pages/Main";
import Login from "pages/Login";
import UsersAsync from "pages/Users";
import Plans from "pages/Plans";
import Securities from "pages/Securities";
import Promo from "pages/Promo";
import Messaging from 'pages/Messaging'
export enum AppRoutes {
  MAIN = '/main',
  LOGIN = '/login',
  USERS = '/users',
  SECURITIES = '/securities',
  PLANS = '/plans',
  PROMO = '/promo',
  MESSAGING = '/messaging',
}

export const routeConfig: Record<AppRoutes, ApprouterProps> = {
  [AppRoutes.MAIN]: {
    path: PATHS.MAIN,
    element: <Main/>,
    authOnly: true
  },
  [AppRoutes.LOGIN]: {
    path: PATHS.LOGIN,
    element: <Login/>
  },
  [AppRoutes.USERS]: {
    path: PATHS.USERS,
    element: <UsersAsync/>,
    authOnly: true
  },
  [AppRoutes.SECURITIES]: {
    path: PATHS.SECURITIES,
    element: <Securities/>,
    authOnly: true
  },
  [AppRoutes.PLANS]: {
    path: PATHS.PLANS,
    element: <Plans/>,
    authOnly: true
  },
  [AppRoutes.PROMO]: {
    path: PATHS.PROMO,
    element: <Promo/>,
    authOnly: true
  },
  [AppRoutes.MESSAGING]: {
    path: PATHS.MESSAGING,
    element: <Messaging/>,
    authOnly: true
  }
};