import {observer} from "mobx-react-lite";
import {useField} from "react-final-form";
import {TextField, MerqButton} from "shared";
import {Credentials} from '../../types'

type LoginFormProps = {
  onSubmit: () => void
}

export const LoginForm = observer(({ onSubmit }: LoginFormProps) => {

  const {input: emailInput, meta: emailMeta} = useField('email')
  const {input: passwordInput, meta: passwordMeta} = useField('password')

  return (
    <>
      <TextField
        {...emailInput}
        {...emailMeta}
        label='Login'
        type='text'
      />
      <TextField
        {...passwordInput}
        {...passwordMeta}
        label='Password'
        type='password'
      />
      <MerqButton variant='primary' size='lg' className='mt-3' onClick={onSubmit}>
        Войти
      </MerqButton>
    </>
  )
})